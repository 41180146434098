.container {
    background-color: #fff;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .content {
    background-color: #fffffffa;
    display: flex;
    flex-flow: row wrap;
    padding: 1rem 0;
    height: 70vh;
    width: 40%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 3px 20px #00000022;
    overflow: hidden;
  }
  .proceed {
    border-radius: 10px;
    position: relative;
    padding: 1rem 0.5rem;
    margin: 0 auto;
    box-shadow: 0 4px 30px #aaa;
    /* overflow: hidden; */
  }
  .proceed-circle:nth-child(1) {
    top: -10%;
    right: -10%;
  }
  .proceed-circle:nth-child(2) {
    bottom: -10%;
    left: -10%;
  }
  @media (max-width: 600px) {
    .proceed-circle:nth-child(1) {
      top: -40%;
      right: -35%;
    }
    .proceed-circle:nth-child(2) {
      bottom: -40%;
      left: -35%;
    }
    /* .proceed {
      width: 100%;
    }*/
  }
  .proceed-circle {
    position: fixed;
    height: 500px;
    width: 500px;
    border-radius: 50%;
    border: 70px solid #00962c11;
  }
  .confirm-email-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
  .email-confirm {
    display: flex;
    flex-flow: column wrap;
    margin: 1rem auto 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
  }
  .email-confirm-variant {
    border: 2px solid #00962c22;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    height: 50vh;
    width: 50%;
    flex-flow: column wrap;
    margin: 1rem auto 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 4rem;
    box-shadow: 0 2px 20px #00000022;
    overflow: hidden;
    position: relative;
  }
  .email-confirm p,
  .email-confirm-variant p {
    margin-top: 1rem;
    opacity: 0.6;
  }
  .email-confirm span,
  .email-confirm-variant span {
    font-weight: 600;
    font-size: 1.2rem;
    display: block;
    color: #00962c;
    margin-top: 0.5rem;
  }
  .email-circle {
    top: -10%;
  }
  .email-circle,
  .email-circle2 {
    position: absolute;
    width: 150px;
    height: 150px;
    right: -10%;
    border: 20px solid #00962c33;
    border-radius: 50%;
  }
  .email-circle2 {
    width: 250px;
    height: 250px;
    bottom: -10% !important;
    left: -20%;
    border: 50px solid #00962c11;
    z-index: 0;
  }
  .offers {
    background: -webkit-linear-gradient(215deg, #01af35, rgb(1, 95, 29));
    position: relative;
    height: inherit;
    width: 50%;
    overflow: hidden;
    /* background: -webkit-linear-gradient(45deg, #2c009655, #00962c55); */
  }
  
  .offers-content {
    background-color: transparent;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    height: 80%;
    width: 70%;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .offers-content h3 {
    padding: 0;
    margin: 0;
  }
  .offer-header {
    font-weight: 600;
    margin-top: 3rem;
  }
  .offer-item {
    background-color: #ffffff55;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    overflow: hidden;
    box-shadow: 0px 2px 10px #33333355;
  }
  .offer-item:hover {
    transform: scale(1.2, 1.1);
    box-shadow: 0px 2px 30px #33333355;
  }
  .offer-item:hover .offer-img {
    transform: rotateZ(-45deg) scale(1.2);
  }
  .offer-img > img {
    filter: drop-shadow(0 5px 10px #33333355);
  }
  .offer-text > h3 {
    margin-left: 1rem;
    color: #fff !important;
  }
  
  .auth-form {
    /* height: inherit; */
    overflow-y: auto;
    width: 70%;
    margin: 0 auto;
  }
  
  @media (max-width: 850px) {
    .content {
      width: 90%;
      height: 85vh;
      overflow: auto;
      display: block;
    }
    .offers {
      width: 100%;
      height: 25vh;
      overflow: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .offers-content {
      flex-flow: row nowrap;
      width: auto;
      margin-top: 0rem;
    }
    .offer-item {
      margin-right: 1rem;
      width: 300px;
    }
    .offer-item:hover {
      transform: scale(1);
    }
  
    .email-confirm-variant {
      width: 90%;
      padding: 1.5rem 1rem;
      height: auto;
    }
    .auth-form {
      width: 100%;
    }
  }
  .copyright {
    position: absolute;
    bottom: 3%;
    text-align: center;
    width: 100%;
  }